// Icons
import PinIcon from 'assets/icons/maps/pin.png';
import SelectedPinIcon from 'assets/icons/maps/selected-pin.png';
import { MAPBOX_PUBLIC_TOKEN } from 'gatsby-env-variables';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl';
import MapboxWorker from 'mapbox-gl/dist/mapbox-gl-csp-worker';
// Styles
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Map, MapRef, Marker, NavigationControl, Popup } from 'react-map-gl';

// Ui-Kit
import Button from 'ui-kit/button/button';
import MapViewIcon from 'ui-kit/illustrations/map-view/map-view';

import { mapPharmacyResultsToPharmacyInfo, PharmacyInfo, PharmacyResults } from 'types/discount-card';
import { CustomGeoJSONFeature, GeoJSONFeatureCollection } from 'types/mapbox';

// Utils
import { createGeoJSON, setBounds, translateToAddressesData } from 'util/mapbox';

import { MapViewProps } from './map-view.props';
import './map-view.styles.scss';

mapboxgl.accessToken = MAPBOX_PUBLIC_TOKEN;
mapboxgl.workerClass = MapboxWorker;

const MAPBOX_INITIAL_ZOOM = 11;

const MapView: React.FC<MapViewProps> = ({
    selectedPharmacy,
    pharmacies,
    onSelectedPharmacy,
    isVisible,
    handleChangeMapVisible
}) => {
    const mapRef = useRef<MapRef>(null);
    const imgRefs = useRef<(HTMLImageElement | null)[]>([]);
    const [lng, setLng] = useState<number | null>(null);
    const [lat, setLat] = useState<number | null>(null);
    const [zoom, setZoom] = useState(MAPBOX_INITIAL_ZOOM);
    const [geoJSON, setGeoJSON] = useState<GeoJSONFeatureCollection | undefined>();
    const [popupInfo, setPopupInfo] = useState<CustomGeoJSONFeature | null>(null);

    const [selectedPinName, setSelectedPinName] = useState<string | null>(null);
    const [viewState, setViewState] = useState({
        longitude: lng || 0,
        latitude: lat || 0,
        zoom: MAPBOX_INITIAL_ZOOM
    });

    const togglePopup = useCallback((feature: CustomGeoJSONFeature | undefined) => {
        if (feature) setPopupInfo(feature);
    }, []);

    const handleShowAllPharmacies = useCallback(() => {
        setPharmacyData(pharmacies);
        setZoom(MAPBOX_INITIAL_ZOOM);
        setSelectedPinName(null);
        onSelectedPharmacy(undefined);
        resetAllPins();
        setPopupInfo(null);

        if (geoJSON?.features?.length) {
            const coordinates = geoJSON.features.map((feature) => feature.geometry.coordinates);
            if (coordinates.length) {
                centerMap(coordinates);
            }
        }
    }, [pharmacies, geoJSON]);

    const centerMap = (coordinates: [number, number][]) => {
        const bounds = coordinates.reduce(
            (bounds, coord) => bounds.extend(coord),
            new mapboxgl.LngLatBounds(coordinates[0], coordinates[0])
        );

        setTimeout(() => {
            mapRef.current?.fitBounds(bounds, { padding: 50, maxZoom: MAPBOX_INITIAL_ZOOM });
        }, 200);
    };

    const handleMarkerClick = (feature: CustomGeoJSONFeature, index: number) => {
        const clickedPharmacy = pharmacies?.find((pharmacy) =>
            pharmacy.locations.some(
                ({ Latitude, Longitude }) =>
                    Latitude === feature.geometry.coordinates[1] && Longitude === feature.geometry.coordinates[0]
            )
        );

        if (clickedPharmacy) {
            const parsedPharmacy = mapPharmacyResultsToPharmacyInfo(clickedPharmacy);
            togglePopup(feature);
            onSelectedPharmacy(parsedPharmacy);
            updatePinAndSetNewOne(index, SelectedPinIcon);
        }
    };

    const updatePinAndSetNewOne = (index: number, newSrc: string) => {
        setTimeout(() => {
            imgRefs.current.forEach((imgRef) => {
                if (imgRef) {
                    imgRef.src = PinIcon;
                }
            });

            if (imgRefs.current[index]) {
                imgRefs.current[index].src = newSrc;
            }
        }, 20);
    };

    const resetAllPins = () => {
        setTimeout(() => {
            imgRefs.current.forEach((imgRef) => {
                if (imgRef) {
                    imgRef.src = PinIcon;
                }
            });
            togglePopup(undefined);
        }, 20);
    };

    const findPharmacyIndex = (selectedPharmacy?: PharmacyInfo) => {
        if (!selectedPharmacy) return 0;
        return pharmacies.findIndex((pharmacy) => pharmacy.name === selectedPharmacy?.PharmacyName);
    };

    const toggleMapVisibility = () => {
        handleChangeMapVisible();
        if (selectedPharmacy) {
            updatePinAndSetNewOne(findPharmacyIndex(selectedPharmacy), SelectedPinIcon);
        }
    };

    const setPharmacyData = (pharmacies: PharmacyResults[]) => {
        const formattedAddresses = translateToAddressesData(pharmacies);

        if (Array.isArray(formattedAddresses) && !formattedAddresses.length) {
            setGeoJSON(undefined);
            return;
        }

        const featureCollection = createGeoJSON(formattedAddresses!);
        setBounds(featureCollection, setLat, setLng);
        setGeoJSON(featureCollection);
    };

    const markers = useMemo(
        () =>
            geoJSON?.features.map((feature, index) => {
                if (selectedPinName === null || feature.properties.name === selectedPinName) {
                    return (
                        <Marker
                            key={index}
                            longitude={feature.geometry.coordinates[0]}
                            latitude={feature.geometry.coordinates[1]}
                            anchor="bottom"
                            onClick={(e) => {
                                e.originalEvent.stopPropagation();
                                handleMarkerClick(feature, index);
                            }}
                        >
                            <img
                                ref={(el) => (imgRefs.current[index] = el)}
                                src={selectedPinName === feature.properties.id ? SelectedPinIcon : PinIcon}
                                alt="Pin"
                                style={{ width: 24, height: 24 }}
                            />
                        </Marker>
                    );
                }
                return null;
            }),
        [geoJSON, handleMarkerClick, selectedPinName]
    );

    useEffect(() => {
        if (lat && lng) {
            setBounds(geoJSON, setLat, setLng);
            setViewState((prev) => ({
                ...prev,
                longitude: lng,
                latitude: lat
            }));
        }
    }, [lat, lng, geoJSON]);

    useEffect(() => {
        if (!pharmacies || pharmacies.length === 0) return;

        if (selectedPharmacy) {
            setSelectedPinName(selectedPharmacy.PharmacyName);
            updatePinAndSetNewOne(findPharmacyIndex(selectedPharmacy), SelectedPinIcon);
            togglePopup(geoJSON?.features[findPharmacyIndex(selectedPharmacy)]);
        } else {
            handleShowAllPharmacies();
        }

        mapRef?.current?.zoomTo(MAPBOX_INITIAL_ZOOM);
    }, [pharmacies, selectedPharmacy]);

    useEffect(() => {
        handleShowAllPharmacies();
    }, []);

    return isVisible && lat && lng ? (
        <div className="map-container">
            <div className="sidebar">
                <span>Showing all pharmacies in your area</span>

                <div className="sidebar-actions">
                    <Button
                        className="btn-text btn-text-blue"
                        label="Hide Map View"
                        type="button"
                        variant="text"
                        onClick={toggleMapVisibility}
                    />
                    {pharmacies && (
                        <Button
                            disabled={!selectedPinName}
                            label="Show All Pharmacies"
                            type="button"
                            className={`${!!selectedPinName ? 'is-active' : ''} small-button btn btn-outline`}
                            variant="outline-smoke"
                            onClick={handleShowAllPharmacies}
                        />
                    )}
                </div>
            </div>
            <Map
                id="displayMap"
                reuseMaps
                ref={mapRef}
                style={{ width: '100%', height: '100%' }}
                {...viewState}
                onMove={(evt) => setViewState(evt.viewState)}
                mapStyle="mapbox://styles/mapbox/streets-v12"
                mapboxAccessToken={MAPBOX_PUBLIC_TOKEN}
                zoom={viewState.zoom}
            >
                <NavigationControl position="top-right" />
                {markers}

                {popupInfo && (
                    <Popup
                        anchor="top"
                        longitude={popupInfo.geometry.coordinates[0]}
                        latitude={popupInfo.geometry.coordinates[1]}
                        onClose={() => setPopupInfo(null)}
                    >
                        <div className="popup-info">
                            <strong>{popupInfo.properties.name}</strong>
                            <br />
                            <span className="popup-info--content">{popupInfo.properties.address}</span>
                            <br />
                            <span className="popup-info--price">{popupInfo.properties.price}</span>
                        </div>
                    </Popup>
                )}
            </Map>
        </div>
    ) : (
        <div className="map-view">
            <MapViewIcon />
            <p>Show all the pharmacies on a map</p>
            <Button
                label="Open Map View"
                type="button"
                variant="outline-primary"
                className="small-button"
                onClick={toggleMapVisibility}
            />
        </div>
    );
};

export default MapView;
